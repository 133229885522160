<template>
  <div class="enter-box">
    <Header/>
    <new-home-header title="企业大数据" @btnClick="btnClick" :content="content"
                     :imgSrc="`${require('@/assets/img/payImages/enterpriseBigData.png')}`" imgStyle="height:400px"
                     :showBtn="true" :free-btn="false"/>
    <item :imageSrc="`${require('@/assets/img/home/bigData_home.png')}`" title="企业大数据监测系统" :content="content2"
          :imgStyle="{width:'470px',height:'265px'}" :showBtn="false"/>
    <item :imageSrc="`${require('@/assets/img/home/dataFx.png')}`" title="企业数据分析" :content="content3"
          :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" :showBtn="false"
          imgRight/>
    <div class="report-box" style="padding-top: 70px;">
      <div class="report-box-content">
        <div class="img-list">
          <div
              :style="`width: 150px; height: 160px;margin-bottom: 30px;background-size: cover;background-image:url(${require('@/assets/img/home/report_bg.png')})`"
              v-for="(item,i) in valueRepors" :key="i">
            <div style="padding-top: 20px;text-align: center;font-size: 12px;">企业大数据</div>
            <div style="font-size: 16px;font-weight: bold;text-align: center;margin-top: 30px;">{{ item.title }}</div>
            <div
                style="width: 90px;height: 30px;background-color: #0052D9;margin: 20px auto 0;font-size: 14px;color: #fff;display: flex;align-items: center;justify-content: center;">
              {{ item.btn }}
            </div>
          </div>
        </div>
        <div class="message">
          <div class="message-title">企业价值评估报告</div>
          <div class="message-content">{{ content4 }}</div>
          <div class="tag-list">
            <div>建立中小企业价值评估模型</div>
            <div>刻画中小企业价值轨迹</div>
            <div>描述中小企业信用、创新、风险等状况</div>
            <div>评价中小企业价值程度</div>
          </div>
        </div>
      </div>
      <div class="btn" style="margin:30px auto 0;" @click="btnClick">下载价值报告</div>
    </div>
    <div class="cooperation">
      <div class="cooperation-box">
        <div class="cooperation-box-title">API接口合作</div>
        <div class="cooperation-box-subTitle">覆盖多维度企业数据，以安全、稳定、灵活的调用体验服务银行、证券、保险、地产、制造业等行业</div>
        <div class="cooperation-content">
          <div class="left">
            <div class="left-title">多维度数据</div>
            <div class="datas">
              <div class="datas-cell" v-for="(item,i) in datas" :key="i">
                <el-image style="width: 40px; height: 40px;margin-right: 20px;" :src="item.icon" fit="fill"/>
                <div>
                  <div class="num">{{ item.num }}</div>
                  <div class="title">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="left-title">数据优势</div>
            <div class="advantage-box">
              <div v-for="(item,i) in advantageList" :key="i" class="advantage-box-card">
                <el-image style="width: 78px; height: 48px;;margin-top: 20px;" :src="item.icon" fit="fill"/>
                <div class="advantage-title">{{ item.title }}</div>
                <div class="advantage-content">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--服务平台（1：数智工厂 2：数字办公 3：财务管理 4：ERP企业资源系统 5：CRM客户关系管理系统 6:SCM供应链管理系统 7：KMS知识管理系统 8：WMS仓库管理系统,9 :数字应用  ）-->
    <applyTrial ref="dialog" :servicePlatform="'企业大数据监测系统'"/>
    <Footer></Footer>
  </div>
</template>
<script>
import bannerList from "@/components/assembly/bannerList.vue";
import newHomeHeader from "@/components/assembly/newHomeHeander";
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";
import item from "@/components/assembly/item";

export default {
  name: "enterpriseBigData",
  components: {bannerList, newHomeHeader, Header, applyTrial, Footer, item},
  data() {
    return {
      datas: [
        {
          icon: `${require('@/assets/img/home/1283.png')}`,
          title: '企业主体',
          num: '2.8亿+'
        },
        {
          icon: `${require('@/assets/img/home/1290.png')}`,
          title: '司法诉讼',
          num: '9000万+ '
        },
        {
          icon: `${require('@/assets/img/home/1294.png')}`,
          title: '知识产权',
          num: '5600万+ '
        },
        {
          icon: `${require('@/assets/img/home/1298.png')}`,
          title: '经营信息',
          num: '3.6亿+ '
        }
      ],
      advantageList: [
        {
          icon: `${require('@/assets/img/home/quanmianlaiyuan.png')}`,
          title: '全面的公开数据来源',
          content: '众多公开政府数据来源，保证数据的合法性、真实性、准确性和有效性。'
        },
        {
          icon: `${require('@/assets/img/home/dashujuku.png')}`,
          title: '庞大的数据库',
          content: '海量企业信息，精准专业信息，全量社会实体信息。'
        },
        {
          icon: `${require('@/assets/img/home/shujuweidu.png')}`,
          title: '详尽的数据维度',
          content: '上百种数据维度，可查询上市信息、企业背景信息、法律诉讼、失信人等。'
        }
      ],
      valueRepors: [
        {
          title: '企业价值报告',
          btn: '专业版'
        },
        {
          title: '企业价值报告',
          btn: '基础版'
        },
        {
          title: '企业股权结构报告',
          btn: '加强版'
        },
        {
          title: '企业高管',
          btn: '投资任职风险'
        },
        {
          title: '企业关联报告',
          btn: '精英版'
        },
        {
          title: '招投标信用报告',
          btn: '精装版'
        }
      ],
      content: '建设“全市中小企业大数据中心”，率先启动市、区县中小企业运行数据共享、共用体系，推动中小企业公共数据跨平台共用，跨地区联通，跨行业共享，以建立统一的中小企业数字底座作为支撑目标。',
      content2: '中小企业大数据智能监测管理系统是通过大数据技术建立智能化监测系统，及时掌握辖区企业运行动态，全方位、立体化分析经济运行指标，清晰为企业完整画像，包括知识产权监测、运行企业监测、企业数据分析、企业风险监测、企业服务监测、银企服务监测、政策支持监测、能源监测、社保监测等20余个板块100多个子版块一体化全链企业数据。',
      content3: '为识别、评估中小企业信用、创新、经营等风险，建设重庆市中小企业信用价值大数据平台，利用大数据技术依法采集、处理、挖掘、呈现全市中小企业工商、税务、司法、知识产权、投融资、能源、疫情等涉企数据，并通过大数据技术对多维涉企数据进行结构化收集与加工，建立企业价值评估模型，刻画企业价值轨迹、描述企业信用、创新、风险等状况，评价中小企业价值程度。同时，对多元化应用场景，推出一系列定制化解决方案，助力用户提高投融资决策、风险控制、企业洞察等方面能力。',
      content4: '为识别、评估中小企业信用、创新、经营等风险，建设重庆市中小企业信用价值大数据平台，利用大数据技术依法采集、处理、挖掘、呈现全市中小企业工商、税务、司法、知识产权、投融资、能源、疫情等涉企数据，并通过大数据技术对多维涉企数据进行结构化收集与加工，建立企业价值评估模型，刻画企业价值轨迹、描述企业信用、创新、风险等状况，评价中小企业价值程度。同时，对多元化应用场景，推出一系列定制化解决方案助力用户提高投融资决策、风险控制、企业洞察等方面能力。',
    }
  },
  methods: {
    btnClick() {
      this.$refs.dialog.show = true;
      // window.open('http://qgdata.bangqichat.com')
    }
  }
}
</script>
<style lang="scss" scoped>
.report-box {
  padding: 50px 0;
  background-color: #fff;

  &-content {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-list {
      width: 540px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

    }
  }

  .message {
    width: 610px;
    box-sizing: border-box;

    &-title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    &-content {
      font-size: 16px;
      line-height: 1.8;
      text-align: justify;
      margin-bottom: 30px;
    }
  }
}

.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    border: 1px solid #fff;
    box-sizing: border-box;

    &:nth-child(1) {
      background-color: #FFD8DC;
      color: #FE273B;
    }

    &:nth-child(2) {
      background-color: #E3E7FF;
      color: #1935DE;
    }

    &:nth-child(3) {
      background-color: #E8FFF9;
      color: #1FC89F;
    }

    &:nth-child(4) {
      background-color: #FFF2E0;
      color: #E88A00;
    }
  }
}

.btn {
  width: 200px;
  height: 50px;
  background-color: #1935DE;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}

.cooperation {
  background-color: #F0F2FF;
  padding: 60px 0;

  &-box {
    width: 1200px;
    margin: auto;

    &-title {
      font-size: 30px;
      font-weight: bold;
    }

    &-subTitle {
      font-size: 16px;
      margin-top: 24px;
    }
  }

  .cooperation-content {
    margin-top: 26px;
    display: flex;
    align-items: center;

    .left {
      width: 450px;
      height: 270px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      &-title {
        font-size: 18px;
        color: #333;
        padding: 20px 20px 0;
      }
    }

    .right {
      flex: 1;
      width: 200px;
      height: 270px;
      background-color: #fff;

      &-title {
        font-size: 18px;
        color: #333;
        padding: 20px 20px 0;
      }

      .advantage-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 20px;

        &-card {
          width: 220px;
          height: 190px;
          border: 1px solid #DBE0FF;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;

          .advantage-title {
            font-size: 16px;
            margin-top: 16px;
          }

          .advantage-content {
            color: #666666;
            font-size: 14px;
            margin-top: 12px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.datas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;

  &-cell {
    width: 50%;
    height: 50%;
    box-sizing: border-box;
    padding-left: 50px;
    display: flex;
    align-items: center;

    .num {
      font-size: 20px;
      color: #FE273B;
    }

    .title {
      font-size: 16px;
      color: #333333;
    }
  }
}

.enter-box {
  min-width: 1360px;
}
</style>
<style scoped>
.enter-box >>> .banner-card-container .right-imgBox {
  position: relative;
  top: 20px;
}
</style>
